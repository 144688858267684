// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.lazyload,
.lazyloading {
	opacity: 0;
    transition-duration: 300ms;
}
.lazyloaded {
	opacity: 1;
}

.hero-area-gradient {
    background-image: linear-gradient($primary-3, transparent, $primary-3);
}

.secondary-text-light {
    color: #d6d6d6 !important;
}

.dubiex-logo {
    transition: 0.3s;
}

.dubiex-logo:hover {
    opacity: 50%;
}

.card-gradient {
    background-image: linear-gradient(transparent, white);
}

.transition-extra-slow {
    transition-duration: 0.6s !important;
}

.img-hover {
    transition-duration: $transition-time-slow;
}

.svg-icon-size {
    width: 1.15rem;
    height: 1.15rem;
}

.img-hover:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.hover-arrow {
    font-family: $font-family-classic;
}

.bg-secondary-light {
    background-color: #f7f7f7 !important;
}

.hero-area-panel {
    background-color: rgba(58, 58, 58, 0.446);
    border-radius: 0.5rem;
}

.nft-category {
    a {
        transition-duration: $transition-time;
        opacity: 60%;
    }

    .nft-icon {
        transition-duration: $transition-time-slow;
    }
}

.nft-category:hover {
    .nft-icon {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
    }
}

.nft-category {
    a.active {
        opacity: 100% !important;
    }
}

.hero-area {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15rem 0 !important;
}

.cos-phone {
    .icon {
        opacity: 65%;
        transition: $transition-time;
    }
}

.cos-phone:hover {
    .icon {
        opacity: 100%;
    }
}

.roadmap-logo {
    transition-duration: $transition-time;
}

.text-shadow {
    text-shadow: 3px 3px 8px $primary-3;
}

.hero-area-arrow {
    opacity: 15%;
    transition-duration: $transition-time;

    .icon {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
    }

    svg.icon * {
        fill: white !important;
    }
}

.white-button {
    background-color: white;
    color: black !important;
    text-shadow: none !important;
    border-radius: 0.35rem;
    transition: $transition-time;

    svg.icon * {
        fill: black !important;
    }
}

.white-button:hover {
    color: black !important;
    background-color: rgb(206, 206, 206);
}

.hero-area-arrow:hover {
    opacity: 40%;
}

.bg-primary-4 {
    background-color: $primary-4;
}

.primary-4-text {
    color: #94c2f8 !important;
}

.blur {
    filter: blur(1px);
    -webkit-filter: blur(1px);
    -moz-filter: blur(1px);
    -o-filter: blur(1px);
}

.exchange-logo-size {
    max-height: 70px;
}

.dubiex-panel {
    border-radius: 0.75rem;
    box-shadow: 0px 0px 8px #4da3f9;
    opacity: 80%;

    img {
        min-width: 10rem;
    }
}

.card {
    border-radius: 1rem;
}

.card-image {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.currency-panel {
    margin: 0.25rem;
    background-color: #030f1f;
    border: 1px solid #06182e;
    box-shadow: 0px 0px 10px #06182e;
    border-radius: 1rem;
    > div {
        min-height: 30rem;
    }
    display: flex;
    flex-direction: column;

    .currency-icon {
        height: 9rem;
    }

    .sub-panel {
        background-color: #041427;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: 1rem 0;
    }
}

@media (min-width: 1200px) {
    .dubiex-section {
        .dubiex-panels {
            border-left: 1px solid #13283d;

            .dubiex-panel {
                margin-left: 1.5rem;
                margin-bottom: 2rem;
            }
        }
    }
}

@media (max-width: 1199px) {
    .dubiex-section {
        .dubiex-panels {
            margin: 0 !important;
            
            .dubiex-panel {
                margin-bottom: 2rem;
                margin-left: 0;
            }
        }

    }

    .dubiex-section {
        .floor-grid {
            display: none;
        }
    }
}

@media (min-width: 993px) {
    .play-button-overlay {
        top: 73%;
    }

    .nft-category {
        padding-top: 1rem;

        .nft-icon {
            height: 8rem;
            min-width: 8rem;
        }
    }

    .title {
        height: 2.5rem;
    }

    .prps-dubi-section {
        .sub-section {
            margin: 0 2.25rem;
        }
    }
}

@media (max-width: 992px) {
    .play-button-overlay {
        top: 60%;
    }
    
    .nft-category {
        .nft-icon {
            height: 5rem;
            min-width: 5rem;
        }

        .lead {
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }

    .title {
        height: 1.75rem;
    }

    .dotted-line {
        display: none;
    }
}

@media (min-width: 768px) {
    .roadmap-logo.left {
        margin-left: 1.5rem;
        margin-right: -1.25rem;
    }

    .roadmap-logo.right {
        margin-left: -1.25rem;
        margin-right: 1.5rem;
    }

    .dubiex-section {
        .sub-text {
            padding-top: 4rem;
        }
    }
}

@media (max-width: 767px) {
    .roadmap-hoverable {
        .text {
            margin: 0 1.5rem;
        }
    }
}


@media (min-width: 601px) {
    .small-phone {
        bottom: -2.5rem;
        right: -2.5rem;
    }

    .nft-text-info {
        text-align: right;
        padding-top: 2.5rem;
    }

    .how-it-works-summary {
        margin: 0 6rem;
        padding: 0 6rem;
    }

    .roadmap-logo {
        width: 2.5rem;
        height: 2.5rem;
    }

    .roadmap-hoverable {
        .extra-info {
            padding-top: 0.5rem;
            font-weight: 100;
            max-width: 30rem;
            position: absolute;
            opacity: 0;
            transition-duration: $transition-time;
        }

        .extra-info.left {
            margin-right: 3rem;
        }
    }

    .roadmap-hoverable:hover {
        .extra-info {
            opacity: 100%;
        }

        .roadmap-logo {
            transform: scale(1.25);
            -webkit-transform: scale(1.25);
            -moz-transform: scale(1.25);
            -o-transform: scale(1.25);
        }
    }

    #game {
        position: absolute;
        top: 2.5rem;
    }

    .hero-area-video {
        width: 75vw;
        height: 85vh;
        top: 0;
        right: -5rem;
        left: 0;
        margin: 0 auto;
        position: absolute;
    }

    .cos-learn-more {
        text-align: right;
    }

    .nft-collection {
        .lead {
            margin-right: 1rem;
        }
    }

    .crypto-section-divider {
        margin: 4.5rem 0;
    }
}

@media (max-width: 600px) {
    .small-phone {
        bottom: -2.5rem;
        left: 0;
        right: 0;
        display: block;
        margin: 0 auto;
    }

    .nft-text-info {
        text-align: center;
        padding-top: 1rem;
        margin: 0 auto;
    }

    .roadmap-logo {
        width: 1.5rem;
        height: 1.5rem;
        margin: 1rem 1.5rem 0 1rem;
        display: block;
    }

    .roadmap-hoverable {
        .extra-info {
            display: none;
        }
    }

    .dubiex-section {
        .transition-image {
            padding-top: 6rem;
        }
    }

    .prps-dubi-section {
        .sub-section {
            margin: 0 0.5rem;
            text-align: center;
        }
    }

    #game {
        position: absolute;
        top: 1rem;
    }

    .hero-area-video {
        width: 120vw;
        top: 0;
        right: 0;
        left: -5rem;
        position: absolute;
    }

    .cos-learn-more {
        text-align: center;
    }

    .hero-area-floor {
        display: none;
    }

    .nft-collection {
        margin: 0 1rem;

        li {
            padding-top: 1rem;
        }
    }

    .nft-panel {
        margin-top: 1rem !important;
    }

    .container {
        padding: 0 1.5rem;
    }

    .card.tab-content {
        width: 65%;
        margin: 0 auto;
    }

    .crypto-section-divider {
        margin: 0;
    }
}